import * as React from 'react';
import { Link } from 'gatsby';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotAvailabePage = () => (
  <main style={pageStyles}>
    <title>Not Available in your country</title>
    <h1 style={headingStyles}>Content not available for your country</h1>
    <p style={paragraphStyles}>
      Sorry
      {' '}
      we couldn’t find what you were looking for.
      <br />
      <Link to="https://www.wellssingapore.com">Go back to Wells website</Link>
      .
    </p>
  </main>
);

export default NotAvailabePage;
